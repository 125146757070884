import {
    BaseModel,
    ProposalInvestment,
    EsignAgreementParticipant,
    Agreement,
    Shortlist,
    Offer,
    Investor,
    Adviser,
    Activity,
    User,
    Holding
} from '@/models';
import ProposalStatusEnum from '@/enums/proposal/status';
import ProposalFundingStatusEnum from '@/enums/proposal/fundingStatus';
import ProposalApplicationStatusEnum from '@/enums/proposal/applicationStatus';
import ProposalRedistributionEnum from '@/enums/proposal/redistribution';
import ProposalSolveAmountForEnum from '@/enums/proposal/solveAmountFor';
import ProposalAdvisedEnum from '@/enums/proposal/advised';
import ProposalFundingSourceEnum from '@/enums/proposal/fundingSource';
import ProposalDirectMarketingMethodEnum from '@/enums/proposal/directMarketingMethod';
import FeeCollectionEnum from '@/enums/feeCollection';
import OfferStatusEnum from '@/enums/offer/status';
import InvestorRoleEnum from '@/enums/investor/role';
import ProposalApi from '@/api/ProposalApi';
import InvestorApi from '@/api/InvestorApi';
import AdviserApi from '@/api/AdviserApi';
import OfferApi from '@/api/OfferApi';
import UserApi from '@/api/UserApi';
import floor from '@/lib/helpers/floor';
import OrganisationTypeEnum from '@/enums/organisation/type';
import ProposalCalculator from '@/calc/ProposalCalculator';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import $m from '@/lib/money';

export class Proposal extends BaseModel {
    static entity = 'proposals';
    static Api = ProposalApi;

    static fields() {
        return {
            ...super.fields(),
            $calc: this.attr(null).nullable(),
            id: this.attr(null),
            activities: this.attr(null).nullable(),
            adviser_fees_get_tax_relief: this.boolean(null).nullable(),
            amount: this.attr(this.defaultMoney).nullable(),

            default_annual_adviser_fee: this.attr(null).nullable(),
            annual_adviser_fee: this.attr(null).nullable(),
            annual_adviser_fee_use_percentage: this.boolean(false).nullable(),
            annual_adviser_fee_collection: this.enum(FeeCollectionEnum, FeeCollectionEnum.FACILITATED_PLATFORM),
            default_annual_adviser_fee_percentage: this.number(null).nullable(),
            annual_adviser_fee_percentage: this.number(null).nullable(),
            annual_adviser_fee_vat: this.boolean(false).nullable(),
            annual_adviser_fee_years_upfront: this.number(2).nullable(),
            default_annual_platform_fee: this.attr(null).nullable(),
            annual_platform_fee: this.attr(null).nullable(),
            annual_platform_fee_use_percentage: this.boolean(true).nullable(),
            default_annual_platform_fee_percentage: this.number(null).nullable(),
            annual_platform_fee_percentage: this.number(null).nullable(),
            annual_platform_fee_years_upfront: this.number(2).nullable(),
            application_amount: this.attr(this.defaultMoney).nullable(),
            application_form_manually_signed: this.boolean(null).nullable(),
            application_on: this.string(null).nullable(),
            application_sent_on: this.string(null).nullable(),
            application_first_viewed_on: this.string(null).nullable(),
            application_last_viewed_on: this.string(null).nullable(),
            application_status: this.enum(ProposalApplicationStatusEnum).nullable(),
            cleared_funds: this.attr(this.defaultMoney).nullable(),
            initial_amount: this.attr(this.defaultMoney).nullable(),
            events: this.attr([]).nullable(),
            funding_status: this.enum(ProposalFundingStatusEnum).nullable(),
            default_initial_adviser_fee: this.attr(null).nullable(),
            initial_adviser_fee: this.attr(null).nullable(),
            initial_adviser_fee_use_percentage: this.boolean(false).nullable(),
            initial_adviser_fee_collection: this.enum(
                FeeCollectionEnum,
                FeeCollectionEnum.FACILITATED_PROVIDER
            ).nullable(),
            default_initial_adviser_fee_percentage: this.number(null).nullable(),
            initial_adviser_fee_percentage: this.number(null).nullable(),
            initial_adviser_fee_vat: this.boolean(false).nullable(),
            investments: this.modelList(ProposalInvestment, []).nullable(),
            on_platform: this.boolean(true).nullable(),
            proposed_at: this.string(null).nullable(),
            redistribution_algorithm: this.enum(ProposalRedistributionEnum).nullable(),
            signatories: this.modelList(EsignAgreementParticipant, []).nullable(),
            solve_amount_for: this.enum(ProposalSolveAmountForEnum, ProposalSolveAmountForEnum.APPLICATION_AMOUNT),
            status: this.enum(ProposalStatusEnum).nullable(),
            value_at: this.string(null).nullable(),
            existing_shareholder_reference: this.string(null).nullable(),
            existing_nominee_reference: this.string(null).nullable(),
            existing_nominee_reference_locked: this.boolean(false).nullable(),
            advice_received: this.enum(ProposalAdvisedEnum).nullable(),
            source_of_funds: this.enumList(ProposalFundingSourceEnum).nullable(),
            direct_marketing_method: this.enumList(ProposalDirectMarketingMethodEnum).nullable(),
            apply_default_distribution: this.boolean(null).nullable(),

            agreement_id: this.string(null).nullable(),
            agreement: this.belongsTo(Agreement, 'agreement_id'),

            shortlist_id: this.string(null).nullable(),
            shortlist: this.belongsTo(Shortlist, 'shortlist_id'),

            client_id: this.string(null).nullable(),
            client: this.belongsTo(Investor, 'client_id'),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id')
        };
    }

    static mock() {
        return {
            id: faker => faker.string.uuid(),
            proposed_at: faker => faker.date.past().toISOString(),
            status: faker =>
                faker.helpers.arrayElement([
                    ProposalStatusEnum.PROPOSED,
                    ProposalStatusEnum.IN_PROGRESS,
                    ProposalStatusEnum.AWAITING_APPROVAL,
                    ProposalStatusEnum.AWAITING_CONFIRMATION,
                    ProposalStatusEnum.PENDING_ALLOTMENT
                ]),
            funding_status: faker => faker.helpers.arrayElement(Object.values(ProposalFundingStatusEnum)),
            redistribution_algorithm: faker => faker.helpers.arrayElement(Object.values(ProposalRedistributionEnum)),

            client: InvestorApi,
            client_id: (faker, item) => item.client.id,

            adviser: AdviserApi,
            adviser_id: (faker, item) => item.adviser.id,

            offer: OfferApi,
            offer_id: (faker, item) => item.offer.id,

            amount: faker => ({
                amount: faker.number.float(10000, 1000000),
                currency: 'GBP'
            }),
            solve_amount_for: faker => faker.helpers.arrayElement(Object.values(ProposalSolveAmountForEnum)),
            adviser_fees_get_tax_relief: faker => faker.datatype.boolean(),
            initial_adviser_fee: faker => ({
                amount: faker.number.float(100, 10000),
                currency: 'GBP'
            }),
            initial_adviser_fee_vat: faker => faker.datatype.boolean(),
            initial_adviser_fee_collection: faker => faker.helpers.arrayElement(Object.values(FeeCollectionEnum)),
            annual_adviser_fee: faker => ({
                amount: faker.number.float(100, 10000),
                currency: 'GBP'
            }),
            annual_adviser_fee_vat: faker => faker.datatype.boolean(),
            annual_adviser_fee_years_upfront: faker => faker.datatype.number(2),
            annual_adviser_fee_collection: () => FeeCollectionEnum.FACILITATED_PLATFORM,
            annual_platform_fee: faker => ({
                amount: faker.number.float(100, 10000),
                currency: 'GBP'
            }),
            annual_platform_fee_years_upfront: faker => faker.datatype.number(2),
            investments: async (faker, item, mock) => {
                let items = [];
                let investment = null;

                if (item.offer.is_fund_offer || !!item.offer.fund_id) {
                    investment = await mock(
                        ProposalInvestment.mock({
                            fund_id: item.offer.fund_id,
                            fund: item.offer.fund,
                            amount: item.amount,
                            share_quantity: null,
                            share_price: null
                        })
                    );

                    items.push(investment[0]);
                } else {
                    const count = item.offer.product_offers.length;
                    const amount = item.amount ? item.amount.amount : 0;
                    const amountPart = amount ? Number((amount / count).toFixed(2)) : 0;

                    for (const po of item.offer.product_offers) {
                        investment = await mock(
                            ProposalInvestment.mock({
                                product_id: po.product_id,
                                product: po.product,
                                share_price: po.offer_price,
                                amount: {
                                    currency: item.amount?.currency || 'GBP',
                                    amount: amountPart
                                }
                            })
                        );

                        items.push(investment[0]);
                    }
                }

                return items;
            },
            events: (faker, item) => {
                return [
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'NOTE',
                        date: item.proposed_at,
                        title: 'Proposal created',
                        text: 'Additional info...',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: null,
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'COMPLETE',
                        date: item.proposed_at,
                        title: 'Accepted',
                        text: "The client has accepted the proposal and we've sent out an application form.",
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'SUCCESS',
                        date: item.proposed_at,
                        title: 'Application form sent for signature',
                        text: 'An application form has been sent to the relevant parties for signature.',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'COMPLETE',
                        date: item.proposed_at,
                        title: 'Client Approval Required',
                        text: 'The proposal requires the approval of the client',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        special_event: 'PROPOSAL_CLIENT_ACCEPT',
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'COMPLETE',
                        date: item.proposed_at,
                        title: 'Adviser Approval Required',
                        text: 'The proposal requires the approval of the adviser',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        special_event: 'PROPOSAL_ADVISER_ACCEPT',
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    })
                ];
            }
        };
    }

    async setupLogic(options = {}, setupId = null) {
        options = {
            ignoreEmptyInvestments: false,
            ...options
        };

        this.stopSetup(setupId);

        await this.setupClient();

        this.stopSetup(setupId);

        if (this.offer_id) {
            if (!this.offer || this.offer.$responseFormat < 20 || this.offer.id !== this.offer_id) {
                this.offer = await Offer.$get(this.offer_id);
            }
        }

        this.stopSetup(setupId);

        if (this.adviser_fees_get_tax_relief === null) {
            this.setInitialAdviserFeeTaxReliefDefault();
        }

        this.stopSetup(setupId);

        await this.setInvestments(options);

        this.stopSetup(setupId);

        this.initial_amount = {
            currency: this.currency,
            amount: this.initial_amount?.amount || 0
        };

        this.amount = {
            currency: this.currency,
            amount: this.amount?.amount || 0
        };

        this.cleared_funds = {
            currency: this.currency,
            amount: this.cleared_funds?.amount || 0
        };

        this.application_amount = {
            currency: this.currency,
            amount: this.application_amount?.amount || 0
        };

        if (this.default_initial_adviser_fee === null && this.Auth().is_adviser) {
            this.default_initial_adviser_fee = {
                amount: 0,
                currency: this.currency || 'GBP'
            };
        }

        if (this.initial_adviser_fee === null) {
            this.initial_adviser_fee = this.default_initial_adviser_fee;
        }

        if (this.default_initial_adviser_fee_percentage === null && this.Auth().is_adviser) {
            this.default_initial_adviser_fee_percentage = 0;
        }

        if (this.initial_adviser_fee_percentage === null) {
            this.initial_adviser_fee_percentage = this.default_initial_adviser_fee_percentage;
        }

        if (this.initial_adviser_fee_vat === null) {
            this.initial_adviser_fee_vat = false;
        }

        if (this.initial_adviser_fee_collection === null) {
            this.initial_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PROVIDER;
        }

        if (this.default_annual_adviser_fee === null && this.Auth().is_adviser) {
            this.default_annual_adviser_fee = {
                amount: 0,
                currency: this.currency || 'GBP'
            };
        }

        if (this.default_annual_adviser_fee_percentage === null && this.Auth().is_adviser) {
            this.default_annual_adviser_fee_percentage = 0;
        }

        if (this.annual_adviser_fee_percentage === null) {
            this.annual_adviser_fee_percentage = this.default_annual_adviser_fee_percentage;
        }

        if (this.annual_adviser_fee_years_upfront === null) {
            this.annual_adviser_fee_years_upfront = 2;
        }

        if (this.annual_adviser_fee_vat === null) {
            this.annual_adviser_fee_vat = false;
        }

        if (this.annual_adviser_fee_collection === null) {
            this.annual_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PLATFORM;
        }

        if (this.annual_platform_fee === null) {
            this.annual_platform_fee = this.default_annual_platform_fee;
        }

        if (this.default_annual_platform_fee_percentage === null) {
            this.default_annual_platform_fee_percentage = this.default_platform_fee;
        }

        if (this.annual_platform_fee_percentage === null) {
            this.annual_platform_fee_percentage = this.default_annual_platform_fee_percentage;
        }

        if (this.annual_platform_fee_years_upfront === null) {
            this.annual_platform_fee_years_upfront = 2;
        }

        this.stopSetup(setupId);

        this.setCalculator();

        this.stopSetup(setupId);

        this.setSignatories();

        await this.setDefaultSignatory();

        this.stopSetup(setupId);

        await this.setExistingNominee();
    }

    get calculator() {
        if (!this.$calc) {
            return this.setCalculator();
        }

        return this.$calc;
    }

    rebaseCalculator() {
        this.$calc = this.$calc.rebase();
    }

    setCalculator(params = {}) {
        this.$calc = ProposalCalculator.create({
            _solve_amount_for: this.solve_amount_for,
            //
            _currency: this.currency,
            _tax_status: this.offer?.tax_status || null,
            _adviser_fees_get_tax_relief: this.adviser_fees_get_tax_relief,
            //
            _default_initial_adviser_fee: this.fromMoney(this.default_initial_adviser_fee, null),
            _default_initial_adviser_fee_percentage: this.default_initial_adviser_fee_percentage,
            _default_annual_adviser_fee: this.fromMoney(this.default_annual_adviser_fee, null),
            _default_annual_adviser_fee_percentage: this.default_annual_adviser_fee_percentage,
            _default_annual_platform_fee: this.fromMoney(this.default_annual_platform_fee, null),
            _default_annual_platform_fee_percentage: this.default_annual_platform_fee_percentage,
            //
            _initial_amount: this.initial_amount?.amount,
            _amount: this.amount?.amount,
            _cleared_funds: this.cleared_funds?.amount,
            _application_amount: this.application_amount?.amount,
            //
            _initial_adviser_fee: this.initial_adviser_fee?.amount,
            _initial_adviser_fee_percentage: this.initial_adviser_fee_percentage,
            _initial_adviser_fee_use_percentage: this.initial_adviser_fee_use_percentage,
            _initial_adviser_fee_collection: this.initial_adviser_fee_collection,
            _initial_adviser_fee_vat: this.initial_adviser_fee_vat,
            //
            _annual_adviser_fee: this.annual_adviser_fee?.amount,
            _annual_adviser_fee_percentage: this.annual_adviser_fee_percentage,
            _annual_adviser_fee_use_percentage: this.annual_adviser_fee_use_percentage,
            _annual_adviser_fee_years_upfront: this.annual_adviser_fee_years_upfront,
            _annual_adviser_fee_collection: this.annual_adviser_fee_collection,
            _annual_adviser_fee_vat: this.annual_adviser_fee_vat,
            //
            _annual_platform_fee: this.annual_platform_fee?.amount,
            _annual_platform_fee_percentage: this.annual_platform_fee_percentage,
            _annual_platform_fee_use_percentage: this.annual_platform_fee_use_percentage,
            _annual_platform_fee_years_upfront: this.annual_platform_fee_years_upfront,
            //
            ...params
        });

        return this.$calc;
    }

    setInitialAdviserFeeTaxReliefDefault() {
        if (this.offer) {
            if (this.offer.provides_tax_relief_on_adviser_fees) {
                this.adviser_fees_get_tax_relief =
                    this.initial_adviser_fee_collection !== FeeCollectionEnum.FACILITATED_PLATFORM;
            } else {
                this.adviser_fees_get_tax_relief = false;
            }
        }
    }

    async setupClient() {
        if (!this.client_id) {
            this.application_form_manually_signed = null;
            return;
        }

        const clientChanged = this.client_id && this.client_id !== this.client?.id;

        if (clientChanged) {
            this.application_form_manually_signed = null;
        }

        if (!this.client || this.client.$responseFormat < 20 || clientChanged) {
            this.client = await Investor.$get(this.client_id);
        }

        if (this.client && !this.client.is_individual && !this.application_form_manually_signed) {
            this.application_form_manually_signed = true;
        }

        if (this.client && this.client.is_individual && this.application_form_manually_signed === null) {
            this.application_form_manually_signed = false;
        }
    }

    getMaxInvestmentAmount(productId) {
        if (!productId) {
            return null;
        }

        const product = this.products.find(p => p.id === productId);

        if (!product) {
            return null;
        }

        return product.max_investment_amount;
    }

    get currency() {
        if (this.amount && 'currency' in this.amount && this.amount.currency) {
            return this.amount.currency;
        }
        if (this.offer && 'currency' in this.offer && this.offer.currency) {
            return this.offer.currency;
        }
        return 'GBP';
    }

    get default_amount() {
        return {
            amount: 0,
            currency: this.currency
        };
    }

    get is_legacy() {
        return !this.investments || !this.investments.length;
    }

    get has_investments() {
        return this.investments && this.investments.length;
    }

    get has_multiple_investments() {
        return this.investments && this.investments.length > 1;
    }

    get has_valid_investments() {
        if (!this.investments) {
            return false;
        }

        return (
            this.has_valid_investment_amount &&
            this.investments.every(investment => {
                if (this.closed_product_ids.includes(investment.product_id)) {
                    return true;
                }

                const min = this.getMinProductInvestmentAmount(investment.product_id);

                if (!min || (investment.amount && !investment.amount.amount)) {
                    return true;
                }

                return investment.amount && investment.amount.amount >= min;
            })
        );
    }

    get has_investment_amount() {
        return this.amount && this.amount.amount;
    }

    get has_no_funding() {
        return this.funding_status === ProposalFundingStatusEnum.AWAITING_FUNDS;
    }

    get has_funding_available() {
        return this.funding_status === ProposalFundingStatusEnum.FUNDS_AVAILABLE;
    }

    get has_funding() {
        return this.funding_status === ProposalFundingStatusEnum.FUNDS_TRANSFERRED;
    }

    get is_aborted() {
        return this.status === ProposalStatusEnum.ABORTED;
    }

    get is_rejected() {
        return this.status === ProposalStatusEnum.REJECTED;
    }

    get is_proposed() {
        return this.status === ProposalStatusEnum.PROPOSED;
    }

    get is_in_progress() {
        return this.status === ProposalStatusEnum.IN_PROGRESS;
    }

    get is_awaiting_approval() {
        return this.status === ProposalStatusEnum.AWAITING_APPROVAL;
    }

    get is_awaiting_confirmation() {
        return this.status === ProposalStatusEnum.AWAITING_CONFIRMATION;
    }

    get is_pending_allotment() {
        return this.status === ProposalStatusEnum.PENDING_ALLOTMENT;
    }

    get has_pending_allotments() {
        return (
            this.investments &&
            this.investments
                .map(inv => (inv instanceof ProposalInvestment ? inv : new ProposalInvestment(inv)))
                .some(investment => investment.is_pending_allotment)
        );
    }

    get is_completed() {
        return this.status === ProposalStatusEnum.COMPLETED;
    }

    get is_shortlisted() {
        return this.status === ProposalStatusEnum.SHORTLISTED || this.status === ProposalStatusEnum.SCHEDULED;
    }

    get is_awaiting_adviser_acceptance() {
        return this.events.find(event => event.special_event === 'PROPOSAL_ADVISER_ACCEPT' && event.todo === true);
    }

    get is_awaiting_client_acceptance() {
        return this.events.find(event => event.special_event === 'PROPOSAL_CLIENT_ACCEPT' && event.todo === true);
    }

    get is_awaiting_acceptance() {
        return this.is_awaiting_adviser_acceptance || this.is_awaiting_client_acceptance;
    }

    get is_awaiting_adviser_fees() {
        if (!this.initial_adviser_fee || this.initial_adviser_fee.amount === null) {
            return true;
        }
        if (!this.annual_adviser_fee || this.annual_adviser_fee.amount === null) {
            return true;
        }
        if (this.annual_adviser_fee_years_upfront === null) {
            return true;
        }
        if (this.annual_adviser_fee_collection === null) {
            return true;
        }

        return false;
    }

    get is_creating() {
        return this.status === null;
    }

    get is_digital() {
        return !this.application_form_manually_signed;
    }

    get is_manual() {
        return this.application_form_manually_signed;
    }

    get application_pending() {
        return this.application_status === ProposalApplicationStatusEnum.PENDING;
    }

    get application_sent() {
        return this.application_status === ProposalApplicationStatusEnum.SENT;
    }

    get application_signed() {
        return this.application_status === ProposalApplicationStatusEnum.SIGNED;
    }

    get application_expired() {
        return this.application_status === ProposalApplicationStatusEnum.EXPIRED;
    }

    get application_aborted() {
        return this.application_status === ProposalApplicationStatusEnum.ABORTED;
    }

    get awaiting_digital_signature() {
        return this.is_digital && this.application_sent;
    }

    get awaiting_digital_application() {
        return this.is_digital && (this.application_aborted || this.application_expired);
    }

    get is_proposable() {
        if (this.offer.status === OfferStatusEnum.CLOSED) {
            return false;
        }

        return this.amount && this.amount.amount > 0;
    }

    get solve_for_application_amount() {
        return this.solve_amount_for === ProposalSolveAmountForEnum.APPLICATION_AMOUNT;
    }

    get solve_for_cleared_funds() {
        return this.solve_amount_for === ProposalSolveAmountForEnum.CLEARED_FUNDS;
    }

    get user_can_update_signatories() {
        if (this.status === null) {
            return true;
        }

        if (this.is_creating || this.is_shortlisted) {
            return true;
        }

        return this.Auth().is_gi;
    }

    get user_can_update_additional_signatory_recipients() {
        if (this.Auth().is_adviser) {
            return true;
        }

        return false;
    }

    get user_can_update_investment() {
        if (this.is_creating || this.is_shortlisted) {
            return true;
        }

        if (this.is_awaiting_confirmation) {
            return false;
        }

        if (this.is_pending_allotment) {
            return false;
        }

        return this.Auth().is_gi;
    }

    get user_can_update_adviser_fees() {
        if (this.is_awaiting_confirmation) {
            return false;
        }

        if (this.is_pending_allotment) {
            return false;
        }

        if (this.is_creating || this.is_shortlisted) {
            return this.Auth().is_adviser;
        }

        return this.Auth().is_gi;
    }

    get user_can_update_platform_fees() {
        if (this.is_awaiting_confirmation) {
            return false;
        }

        if (this.is_pending_allotment) {
            return false;
        }

        return this.Auth().is_gi;
    }

    get client_cash_balance() {
        return this.client?.cash_balance || null;
    }

    get has_default_platform_fee() {
        return this.client && !this.client.fee_overall;
    }

    get using_default_platform_fee() {
        return this.annual_platform_fee_percentage === this.default_platform_fee;
    }

    get default_platform_fee() {
        return this.client?.fee_overall || 0.0025;
    }

    get offer_closed() {
        if (!this.offer || !this.offer.status) {
            return false;
        }
        return this.offer.is_closed;
    }

    get offer_coming_soon() {
        if (!this.offer || !this.offer.status) {
            return false;
        }
        return this.offer.is_coming_soon;
    }

    get has_signatories() {
        return this.signatories && this.signatories.length;
    }

    get adviser_signatory() {
        return this.signatories.find(s => s.role === 'ADVISER' && !s.cc && s.user_id)?.user || new User();
    }

    get investor_signatory() {
        return this.signatories.find(s => s.role === 'INVESTOR' && !s.cc && s.user_id)?.user || new User();
    }

    get is_awaiting_signatories() {
        if (this.application_form_manually_signed) {
            return false;
        }

        if (!this.has_signatories) {
            return false;
        }

        return this.signatories.filter(s => !s.cc).some(s => !s.user_id);
    }

    get is_awaiting_financial_advice() {
        return !this.advice_received;
    }

    get offer_has_minimum_investment() {
        if (!this.offer) {
            return true;
        }

        return this.offer.min_investment && this.offer.min_investment.amount && this.offer.min_investment.amount > 0;
    }

    get offer_minimum_amount() {
        if (!this.offer) {
            return null;
        }

        return this.offer.min_investment;
    }

    get has_valid_min_amount() {
        if (!this.offer) {
            return true;
        }

        if (!(this.offer instanceof Offer)) {
            return true;
        }

        return this.offer.isAmountMinimumValid(this.total_investment_amount);
    }

    get has_valid_max_amount() {
        if (!this.offer) {
            return true;
        }

        if (!(this.offer instanceof Offer)) {
            return true;
        }

        return this.offer.isAmountMaximumValid(this.total_investment_amount);
    }

    get has_valid_investment_amount() {
        return this.has_valid_min_amount && this.has_valid_max_amount;
    }

    get has_valid_adviser_fee_amount() {
        if (!this.has_investment_amount) {
            return true;
        }

        if (this.initial_adviser_fee === null || this.initial_adviser_fee.amount === null) {
            return false;
        }

        if (this.annual_adviser_fee === null || this.annual_adviser_fee.amount === null) {
            return false;
        }

        return true;
    }

    get for_vct() {
        return this.offer && this.offer.is_vct;
    }

    get precision() {
        if (this.offer?.is_single_company || this.offer?.single_company) {
            return 2;
        }

        return 0;
    }

    get offer_products() {
        return this.offer && this.offer.product_offers.filter(po => !po.$deleted);
    }

    get products() {
        return (
            this.offer &&
            this.offer_products.filter(op => !this.closed_product_ids.includes(op.product_id)).map(op => op.product)
        );
    }

    getMinProductInvestmentAmount(productId) {
        const offerProduct = this.offer_products
            .filter(op => !this.closed_product_ids.includes(op.product_id))
            .find(po => po.product_id === productId);

        if (!offerProduct || !offerProduct.minimum_investment_amount) {
            return null;
        }

        return offerProduct.minimum_investment_amount?.amount || null;
    }

    get has_multiple_products() {
        return this.offer_products && this.offer_products.length > 1;
    }

    get closed_product_ids() {
        return this.offer && this.offer.closed_product_ids;
    }

    get has_closed_product_with_investment() {
        if (!this.closed_product_ids) {
            return false;
        }

        return this.closed_product_ids.some(productId => {
            const investment = this.investments.find(inv => inv.product_id === productId);
            return investment && investment.amount && investment.amount.amount > 0;
        });
    }

    // Signatories
    get signatories_not_cc() {
        const signatories = this.signatories
            .filter(signatory => !signatory.cc)
            .map(signatory => {
                if (signatory instanceof EsignAgreementParticipant) {
                    return signatory;
                }

                return new EsignAgreementParticipant(signatory);
            });

        signatories.sort((a, b) => a.order - b.order);

        return signatories;
    }

    // Additional signatories
    get recipients() {
        const recipients = this.signatories
            .filter(signatory => signatory.cc)
            .map(signatory => {
                if (signatory instanceof EsignAgreementParticipant) {
                    return signatory;
                }

                return new EsignAgreementParticipant(signatory);
            });

        recipients.sort((a, b) => a.order - b.order);

        return recipients;
    }

    canTransition(newStatus) {
        if (!newStatus) {
            return false;
        }

        if (this.status === newStatus) {
            return false;
        }

        const todos = this.events.filter(ev => ev.todo !== null);

        if (todos.filter(ev => ev.title !== 'Funds received').every(ev => ev.todo === false)) {
            return true;
        }

        return false;
    }

    async resetOrganisations() {
        const auth = this.Auth();

        if (auth.is_adviser && !auth.is_gi) {
            this.adviser_id = auth.organisation_id;
        } else if (auth.is_investor) {
            this.client_id = auth.organisation_id;
            this.client = await Investor.$get(this.client_id);
            this.adviser_id = auth.account_id;
        }
    }

    setSignatories() {
        if (this.client && !this.client.is_individual) {
            this.signatories = [];
            return;
        }

        if (this.application_form_manually_signed) {
            this.signatories = [];
            return;
        }

        if (this.offer && Array.isArray(this.offer.required_signatories)) {
            const requiredSignatureKeys = this.offer.required_signatories;
            const requiredSignatures = this.offer.application_form_required_signatures;

            let signatories = this.signatories || [];

            for (let signatureKey of requiredSignatureKeys) {
                const signatoryConfig = requiredSignatures.find(rs => rs.signature_key === signatureKey);
                const signatory = signatories.find(s => s.signature_key === signatureKey && !s.cc);

                if (!signatory) {
                    signatories.unshift(
                        new EsignAgreementParticipant({
                            signature_key: signatureKey,
                            cc: false,
                            organisation_role: signatoryConfig ? signatoryConfig.organisation_role : null,
                            order: signatoryConfig ? signatoryConfig.order : null
                        })
                    );
                } else {
                    signatory.organisation_role = signatoryConfig ? signatoryConfig.organisation_role : null;
                    signatory.order = signatoryConfig ? signatoryConfig.order : null;
                }
            }

            this.signatories = signatories;
        }
    }

    async setDefaultSignatory() {
        if (!this.client_id) {
            return;
        }

        if (this.application_form_manually_signed) {
            return;
        }

        const signatories = this.signatories_not_cc;

        if (signatories.every(signatory => signatory.user_id)) {
            return;
        }

        // Set default investor signatory

        const investorSignatory = signatories.find(
            signatory => signatory.organisation_role === OrganisationTypeEnum.INVESTOR
        );

        if (investorSignatory && (!investorSignatory.user_id || !investorSignatory.user)) {
            try {
                const users = await new UserApi().methods.index(0, 100, null, { role_in_organisation: this.client_id });

                if (!users || !users.data || !users.data.length) {
                    return;
                }

                const user = users.data.find(user =>
                    user.roles.find(role => role.roles.includes(InvestorRoleEnum.OWNER))
                );

                if (!user) {
                    return;
                }

                for (let i = 0; i < signatories.length; i++) {
                    if (signatories[`${i}`].organisation_role === OrganisationTypeEnum.INVESTOR) {
                        signatories[`${i}`].user = user;
                        signatories[`${i}`].user_id = user.id;
                        break;
                    }
                }
            } catch (error) {
                console.error('Error setting default investor signatory:', error);
            }
        }

        // Set default adviser signatory

        const adviserSignatory = signatories.find(
            signatory => signatory.organisation_role === OrganisationTypeEnum.ADVISER
        );
        const adviserAccount = this.client.adviser_accounts.find(aa => aa.adviser_id === this.adviser_id);

        if (
            adviserAccount &&
            adviserAccount?.primary_contact?.id &&
            (!adviserSignatory || !adviserSignatory.user_id || !adviserSignatory.user)
        ) {
            try {
                const adviserUsers = await new UserApi().methods.index(0, 1, null, {
                    id: 'eq:' + adviserAccount.primary_contact.id,
                    fca_number: 'neq:null',
                    role_in_organisation: `${this.adviser_id}`
                });

                if (!adviserUsers || !adviserUsers.data || !adviserUsers.data.length) {
                    return;
                }

                const adviserUser = adviserUsers.data.find(Boolean);

                if (!adviserUser) {
                    return;
                }

                for (let i = 0; i < signatories.length; i++) {
                    if (signatories[`${i}`].organisation_role === OrganisationTypeEnum.ADVISER) {
                        signatories[`${i}`].user = adviserUser;
                        signatories[`${i}`].user_id = adviserUser.id;
                        break;
                    }
                }
            } catch (error) {
                console.error('Error setting default adviser signatory:', error);
            }
        }

        this.signatories = signatories;
    }

    async setExistingNominee() {
        this.existing_nominee_reference = null;
        this.existing_nominee_reference_locked = false;

        if (this.is_aborted || this.is_completed || this.is_rejected) {
            return;
        }

        if (!this.client_id) {
            return;
        }

        const productIds = Array.isArray(this.products) && this.products.map(p => p.id);

        if (!productIds.length) {
            return;
        }

        const holdings = await Holding.api.index(0, 1, null, {
            investor_id: `${getFilterOperator('is')}:${this.client_id}`,
            product_id: `${getFilterOperator('in')}:[${productIds.join(',')}]`,
            in_custody: `${getFilterOperator('is')}:true`
        });

        if (!holdings?.data?.length) {
            return;
        }

        this.existing_nominee_reference = 'James Brearley';
        this.existing_nominee_reference_locked = true;
    }

    getOfferProduct(productId) {
        if (!productId) {
            return null;
        }

        if (this.offer) {
            return this.offer.getOfferProduct(productId);
        }

        return null;
    }

    async setInvestments(options = {}) {
        if (this.apply_default_distribution === null) {
            this.apply_default_distribution = true;
        }

        if (!this.has_investments && !options.ignoreEmptyInvestments) {
            await this.resetInvestments();
        }

        if (this.open_product_offers_without_investment) {
            await this.addMissingOpenInvestments();
        }

        if (this.closed_product_offers_without_investment) {
            await this.addMissingClosedInvestments();
        }

        this.investments = this.investments.map(investment => {
            if (!(investment instanceof ProposalInvestment)) {
                investment = new ProposalInvestment(investment);
            }

            investment.closed = this.closed_product_ids.includes(investment.product_id);
            investment.precision = this.precision;

            if (investment.product_id) {
                const po = this.getOfferProduct(investment.product_id);

                if (!po) {
                    throw new Error(`Offer has no matching product offer for product ${investment.product_id}`);
                }

                investment.minimum_amount = po.minimum_investment_amount?.amount || 0;
                investment.default_investment_percentage = po.default_investment_percentage;

                if (!investment.amount || investment.amount?.amount === null) {
                    investment.amount = this.toMoney(0);
                }
            }

            return investment;
        });

        if (Array.isArray(this.investments)) {
            this.investments.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    setInvestment(investment) {
        this.investments = this.investments.map(item => {
            if (!(item instanceof ProposalInvestment)) {
                item = new ProposalInvestment(item);
            }

            if (
                (item.product_id && item.product_id === investment.product_id) ||
                (item.fund_id && item.fund_id === investment.fund_id)
            ) {
                return investment;
            }

            return item;
        });

        const investmentAmount = this.total_investment_amount;
        this.calculator.amount = investmentAmount;
        this.amount = investmentAmount;
    }

    async resetInvestments() {
        this.investments = this.getDefaultInvestments();
    }

    getDefaultInvestments() {
        let investments = [];

        if (this.offer) {
            if (this.offer.is_fund) {
                investments.push(
                    new ProposalInvestment({
                        fund_id: this.offer.fund_id,
                        fund: this.offer.fund,
                        share_price: null,
                        default_investment_percentage: 1,
                        distribution_percentage: 1,
                        precision: this.precision
                    })
                );
            } else {
                for (const po of this.offer.product_offers) {
                    investments.push(
                        new ProposalInvestment({
                            product_id: po.product_id,
                            product: po.product,
                            share_price: po.offer_price,
                            default_investment_percentage: po.default_investment_percentage,
                            distribution_percentage: po.default_investment_percentage,
                            closed: this.closed_product_ids.includes(po.product_id),
                            precision: this.precision
                        })
                    );
                }
            }
        }

        return investments;
    }

    async addMissingOpenInvestments() {
        const open = this.open_product_offers_without_investment.map(
            po =>
                new ProposalInvestment({
                    product_id: po.product_id,
                    product: po.product,
                    amount: this.toMoney(0),
                    share_quantity: 0,
                    share_price: po.offer_price,
                    distribution_percentage: 0,
                    default_investment_percentage: po.default_investment_percentage,
                    closed: false
                })
        );

        this.investments = [...this.investments, ...open];
    }

    async addMissingClosedInvestments() {
        const closed = this.closed_product_offers_without_investment.map(
            po =>
                new ProposalInvestment({
                    product_id: po.product_id,
                    product: po.product,
                    amount: this.toMoney(0),
                    share_quantity: 0,
                    share_price: po.offer_price,
                    distribution_percentage: 0,
                    default_investment_percentage: po.default_investment_percentage,
                    closed: true
                })
        );

        this.investments = [...this.investments, ...closed];
    }

    get closed_default_distribution() {
        return this.offer.product_offers.reduce((distribution, po) => {
            if (!this.closed_product_ids.includes(po.product_id)) {
                return distribution;
            }

            return distribution + po.default_investment_percentage;
        }, 0);
    }

    get closed_distribution() {
        return this.offer.product_offers.reduce((distribution, po) => {
            if (!this.closed_product_ids.includes(po.product_id)) {
                return distribution;
            }

            const investment = this.investments.find(inv => inv.product_id === po.product_id);

            if (investment) {
                return distribution + (investment.distribution_percentage || 0);
            }

            return distribution;
        }, 0);
    }

    get has_open_products() {
        let investmentCount = this.investments.length;

        if (Array.isArray(this.closed_product_ids) && this.closed_product_ids.length > 0) {
            investmentCount -= this.closed_product_ids.length;
        }

        return investmentCount > 0;
    }

    get closed_product_offers() {
        return this.offer.product_offers.filter(po => this.closed_product_ids.includes(po.product_id));
    }

    get closed_product_offers_without_investment() {
        return this.closed_product_offers.filter(po => !this.investments.some(inv => inv.product_id === po.product_id));
    }

    get open_product_offers() {
        return this.offer.product_offers.filter(po => !this.closed_product_ids.includes(po.product_id));
    }

    get open_product_offers_without_investment() {
        return this.open_product_offers.filter(po => !this.investments.some(inv => inv.product_id === po.product_id));
    }

    get has_closed_investment_with_amount() {
        return this.investments.filter(inv => inv.closed && inv.amount && inv.amount.amount > 0).length > 0;
    }

    get open_investments_have_full_distribution() {
        return (
            this.investments
                .filter(inv => !inv.closed)
                .reduce((acc, inv) => {
                    return $m(acc).add(inv.distribution_percentage).value;
                }, 0) === 1
        );
    }

    async recalculateInvestments(amount = null) {
        this.investments = this.getCalculatedInvestments(amount || this.amount);
    }

    getCalculatedInvestments(amount = null) {
        amount = this.fromMoney(amount);

        if (!this.has_open_products) {
            return [];
        }

        let investments = this.investments;

        if (!Array.isArray(investments)) {
            investments = this.getDefaultInvestments();
        }

        if (!amount || Number.isNaN(amount)) {
            return investments.map(investment => {
                investment.amount = this.toMoney(0);
                investment.share_quantity = null;

                return investment;
            });
        }

        const totalInvestmentAmount = this.fromMoney(this.total_investment_amount);

        for (let investment of investments) {
            if (investment.is_closed) {
                continue;
            }

            let investmentAmount = 0;

            if (!this.has_multiple_investments) {
                investmentAmount = this.fromMoney(amount);
            } else if (this.apply_default_distribution) {
                investmentAmount = $m(investment.default_investment_percentage).multiply(amount).value;
            } else if (investment.distribution_percentage) {
                investmentAmount = $m(investment.distribution_percentage).multiply(amount).value;
            } else if (this.fromMoney(investment.amount)) {
                const percentage = $m(this.fromMoney(investment.amount)).divide(totalInvestmentAmount);
                investmentAmount = $m(percentage).multiply(amount);
            } else {
                investmentAmount = 0;
            }

            investment.amount = this.toMoney(investmentAmount);

            investment.recalculateShareQuantity();
        }

        investments = this.applyRemainderToLastInvestment(investments, this.precision);

        return investments;
    }

    applyRemainderToLastInvestment(investments = [], precision = 2) {
        let remainder = 0;

        investments = investments.map(iv => {
            const ivAmount = iv.amount?.amount || 0;
            const ivRemainder = floor($m(ivAmount).subtract(floor(ivAmount, precision)).value, 2);

            if (ivRemainder) {
                remainder += ivRemainder;
                iv.amount.amount = floor(ivAmount, precision);

                console.info(`Remainder of ${ivRemainder} when rounding ${iv.name} to ${iv.amount.amount}`);
            }

            return iv;
        });

        remainder = floor(remainder, precision);

        if (remainder) {
            const lastInvestment = (investments.filter(i => i.is_open) || []).reverse().find(Boolean);
            lastInvestment.amount.amount = $m(lastInvestment.amount.amount).add(remainder).value;
            console.info(`Total remainder of ${remainder} has been added to ${lastInvestment.name}`);
        }

        return investments;
    }

    getDefaultInvestmentPercentage(productId) {
        return this.getDefaultInvestmentPercentages().find(op => op.id === productId)?.percentage || 0;
    }
    getDefaultInvestmentPercentages() {
        return this.offer_products.map(op => ({
            id: op.product_id,
            name: op.product.name,
            percentage: $m(op.default_investment_percentage).divide(1 - this.closed_default_distribution).value
        }));
    }

    get has_default_distribution() {
        const distributions = this.getDefaultInvestmentPercentages();

        return this.investments.every(investment => {
            const investmentPercentage = (investment.amount.amount / this.total_investment_amount.amount).toFixed(2);
            const defaultPercentage = (
                distributions.find(dp => dp.id === investment.product_id)?.percentage || 0
            ).toFixed(2);
            return investmentPercentage === defaultPercentage;
        });
    }

    get total_investment_amount() {
        const total = {
            amount: 0,
            currency: this.currency || 'GBP'
        };

        if (this.investments && Array.isArray(this.investments)) {
            total.amount = this.investments.reduce((total, investment) => {
                return total + (investment.amount?.amount || 0);
            }, 0);
        }

        return total;
    }

    get total_distribution_percentage() {
        return this.investments.reduce((total, investment) => {
            return total + (investment.distribution_percentage || 0);
        }, 0);
    }

    get valid() {
        return {
            source_of_funds: !this.for_vct || (this.source_of_funds && this.source_of_funds.length)
        };
    }

    get adviser_fees() {
        return this.toMoney(this.calculator.total_adviser_fees);
    }

    get platform_fees() {
        return this.toMoney(this.calculator.total_platform_fees);
    }
}

export default Proposal;
